.App {
  display: grid;
  place-content: center;
  height: 100vh;

  background-color: black;

  p {
    color: white;
    font-size: 3rem;
  }
}